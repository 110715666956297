<template>
  <mfc-input-box :errors="cmpErrors" :clean="clean" :is-cleared="isCleared">
    <input ref="input"
           :class="{filled: cmpValue}"
           :name="name"
           v-model="cmpValue"
           @input="update"
           @click="cmpErrors = []"
           @blur="getBlur(clean, $event)"
           v-bind="$attrs">
    <label @click="focused">
      {{label}}
    </label>
    <div class="btn-additional">
      <slot name="additional"></slot>
    </div>
  </mfc-input-box>
</template>

<script>
  import inputMixin from '../../mixins/inputMixin'
  import MfcInputBox from './MfcInputBox'

  export default {
    name: "MfcInput",
    props: {
      vMask: {
      },
    },
    components: {
      MfcInputBox
    },
    mixins: [inputMixin],
    data() {
      return {}
    },
    computed: {
      isCleared() {
        return this.allowClean === false ? this.allowClean : !!this.cmpValue && !this.disabled
      },
    },
    methods: {
      update(e) {
        this.$emit('input', this.cmpValue)
      },
      clean() {
        this.cmpValue = null
        this.cmpErrors = []
        this.update()
      },
      focused() {
        this.$refs.input.focus()
      },
      getInput() {
        return this.$refs.input
      }
    },
    mounted () {
      let vc = this;
      let input = this.$el.getElementsByTagName("input")[0];
      if (input && vc.vMask)
        input.setAttribute("v-mask", `${vc.vMask}`)
    },
    watch: {
      value(n) {
        if (n !== this.cmpValue)
          this.cmpValue = n
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables';

  $error-color: rgb(237, 83, 56);
  $transition-dur: .2s;

  .input-box {
    &.with-error {
      input:not(:focus) {
        border-color: $error-color;
      }
    }
  }

  label {
    position: absolute;
    left: 16px;
    top: 20px;
    color: rgb(135, 135, 135);
    transition: all $transition-dur;
    cursor: text;
    overflow-y: hidden;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 57px);
  }

  input {
    width: 100%;
    box-sizing: border-box;
    background: rgb(245, 245, 245);
    font-weight: 400;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
    outline: none;
    transition: all $transition-dur;
    cursor: text !important;
    box-shadow: none;

    &:-webkit-autofill {
      /*box-shadow: 0 0 0 1000px rgba(255,255,255,1) inset;*/
    }

    .input-box.white & {
      background: rgb(255, 255, 255);
      &:-webkit-autofill {
        box-shadow: 0 0 0 1000px rgba(255,255,255,1) inset;
      }
    }

    .input-box.white.v2 & {
      background: rgb(255, 255, 255);
      border: 1px solid rgb(218, 218, 218);
      &:-webkit-autofill {
        box-shadow: 0 0 0 1000px rgba(255,255,255,1) inset;
      }
    }

    .input-box.bordered & {
      border: 1px solid rgb(245, 245, 245);
      border-radius: 5px;
      padding: 30px 40px 8px 16px;

      // & ~ label {
      //   display: none;
      // }

      &::placeholder {
        color: rgba(117, 115, 111, 1);
      }

      &:focus {
        background: rgb(255, 255, 255);
        border: 1px solid rgb(218, 218, 218);
        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset;
        }
      }

      &:focus ~ label, &.filled ~ label {
        font-size: 14px;
        top: 11px;
      }

      &.filled {

        &:not(:focus) {
          // border-color: $primary-color;
          &:-webkit-autofill {
            box-shadow: 0 0 0 1000px rgb(245, 245, 245) inset;
          }
        }

        &:disabled, &:read-only {
          background-color: rgb(245, 244, 242);
          border-color: transparent;
          color: rgba(117, 115, 111, 1);
        }

        &:disabled ~ label, &:read-only ~ label {
          color: rgb(158, 155, 152);
        }

        &:invalid {
          border-color: $error-color;
        }

        &:invalid ~ .btn-clean {
          background-color: $error-color;
        }
      }

      &:disabled, &:read-only {
        // background-color: rgb(245, 244, 242);
        border-color: transparent;
      }

      &:disabled ~ label, &:read-only ~ label {
        color: rgb(223, 222, 218)
      }

      &:disabled::placeholder, &:read-only::placeholder {
        color: rgb(223, 222, 218);
      }
    }

    .input-box.v3 & {
      border: none;
      font-size: 13px;
      border-radius: 5px;
      padding: 12px 40px 12px 16px;

      ~ label {
        font-size: 13px;
        top: 18px;
      }

      ~ .btn-additional {
        position: absolute;
        top: 15px;
        right: 17px;
      }

      &::placeholder {
        color: rgba(166, 166, 166, 1);
      }


      &:focus, &.filled {
        padding: 21px 40px 3px 16px;
        background: rgb(255, 255, 255);
      }

      &:focus ~ label, &.filled ~ label {
        font-size: 10px;
        top: 11px;
      }
    }

    .input-box.v3.bordered & {
      border: 1px solid rgba(26, 26, 26, 0.1);
      .btn-additional {
        right: 16px !important;
      }
    }
  }

</style>
